import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import CaseStudyContent from "../../sections/case-study/CaseStudyContent"

import caseStudyBgUrl from "../../../media/case-study-banner-overlay.jpg"

import { moodysIntegrationContentList } from "../../sections/case-study/data"

const caseStudyTitle = "Grandview integrates with Moody’s Analytics to drive transparency for CLO Managers and Investors"
const caseStudyDescription = ""

const MoodysIntegrationCaseStudy = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title={caseStudyTitle} />
    <SubHero
      backgroundImage={caseStudyBgUrl}
      title={caseStudyTitle}
      description={caseStudyDescription}
    />
    <CaseStudyContent
      content={moodysIntegrationContentList}
    />
  </Layout>
)

export default MoodysIntegrationCaseStudy
